export function calculadoraDePropiedades(expression: string, formGroup: any): number | null {
  const operators = ['+', '-', '*', '/'];

  // Tokenize the expression into individual terms
  const tokens = expression
    .split(/([+\-*/()])/)
    .map(token => token.trim())
    .filter(token => token.length > 0);

  // Operator precedence
  const precedence: { [key: string]: number } = {
    '+': 1,
    '-': 1,
    '*': 2,
    '/': 2
  };

  const outputQueue: string[] = [];
  const operatorStack: string[] = [];

  // Shunting Yard algorithm
  for (const token of tokens) {
    if (!operators.includes(token) && token !== '(' && token !== ')') {
      // It's an operand, so push it to the output queue
      outputQueue.push(token);
    } else if (operators.includes(token)) {
      // It's an operator
      while (
        operatorStack.length > 0 &&
        operators.includes(operatorStack[operatorStack.length - 1]) &&
        precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]
      ) {
        // Pop operators from the stack to the output queue
        outputQueue.push(operatorStack.pop() as string);
      }
      operatorStack.push(token);
    } else if (token === '(') {
      // Left parenthesis, push to the operator stack
      operatorStack.push(token);
    } else if (token === ')') {
      // Right parenthesis, pop operators from the stack to the output queue
      while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== '(') {
        outputQueue.push(operatorStack.pop() as string);
      }
      if (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] === '(') {
        operatorStack.pop();
      } else {
        // Mismatched parentheses
        return null;
      }
    }
  }

  // Pop any remaining operators from the stack to the output queue
  while (operatorStack.length > 0) {
    const operator = operatorStack[operatorStack.length - 1];
    if (operator === '(' || operator === ')') {
      // Mismatched parentheses
      return null;
    }
    outputQueue.push(operatorStack.pop() as string);
  }

  // Evaluate the postfix expression
  const evalStack: number[] = [];

  for (const token of outputQueue) {
    if (!operators.includes(token)) {
      // Operand, push to the evaluation stack
      if (formGroup.hasOwnProperty(token)) {
        evalStack.push(parseFloat(formGroup[token]));
      } else {
        return null; // Operand not found in formGroup
      }
    } else {
      // Operator, perform the operation
      const rightOperand = evalStack.pop();
      const leftOperand = evalStack.pop();
      if (leftOperand === undefined || rightOperand === undefined) {
        return null; // Insufficient operands
      }
      switch (token) {
        case '+':
          evalStack.push(leftOperand + rightOperand);
          break;
        case '-':
          evalStack.push(leftOperand - rightOperand);
          break;
        case '*':
          evalStack.push(leftOperand * rightOperand);
          break;
        case '/':
          if (rightOperand === 0) {
            return null; // Division by zero
          }
          evalStack.push(leftOperand / rightOperand);
          break;
      }
    }
  }

  if (evalStack.length !== 1) {
    return null; // Invalid expression
  }

  return evalStack[0];
}
